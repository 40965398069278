#channel-page {
    width: calc(100% - 72px);
    min-height: calc(100vh - 56px);
    background-color: var(--black);
}


#channel-header {
    width: 100%;
    max-height: 400px;
}

#channel-banner {
    width: 100%;
    overflow: hidden;
}
#channel-header img {
    object-fit: cover;
    width: 100%;
}

/* BOTTOM OF HEADER / BUTTONS */
#channel-header__actions {
    max-width: 1280px;
    width: 100%;
    height: 160px;
    padding: 24px 60px 0;
    background-color: var(--gray-darkest);
    display: flex;
    justify-content: center;
}

.channel-info {
    height: 100px;
    margin-bottom: 10px;
}

#channel__tabs-container {
    height: 48px;
}

.tab {
    height: 100%;
    padding: 2px 32px 0;
    border-bottom: 2px solid transparent;
    color: var(--gray-light);
    
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: border-bottom-color .25s ease-in-out;
}
.tab:hover {
    color: var(--white);
    
}

.active-tab {
    color: var(--white);
    border-bottom: 2px solid var(--white);
    padding-top: 2px;
}
