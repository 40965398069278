#header {
    width: 100%;
    height: 56px;
    padding: 0 16px;
    background-color: #212121f3;
    
    position: fixed;
    top: 0;
    z-index: 1000;
}

/* LEFT ********************************************/
.logo-wrapper {
    padding: 18px 14px 18px 16px;
    width: 120px;
    height: 56px;
}

#header__logo {
    width: 100%;
    object-fit: cover;
}


/* MIDDLE ******************************************/
#header__middle {
    max-width: 650px;
    width: 50%;
}


/* RIGHT ********************************************/
#header__right {
    width: 220px;
}

.pfp-wrapper-nav {
    margin: 0 12px;
    width: 34px;
    height: 34px;
}

.sign-in-btn {
    padding: 0 1em;
    font-weight: 500;
}
.sign-in-btn span {
    width: 60px;
}

.svg-blue {
    filter: invert(61%) sepia(100%) saturate(2282%) hue-rotate(183deg) brightness(97%) contrast(110%);
    width: 26px;
}
