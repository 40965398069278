#video-page {
    width: 87%;
    margin: 0 auto;
    padding: 24px 0;
    display: grid;
    grid-template-columns: 74% 26%;
    grid-template-areas: "left right";
    align-items: start;
}

#video-page .left {
    grid-area: left;
}

#video-page .right {
    grid-area: right;
    width: 100%;
}



.video-wrapper {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
}
#video-page iframe {
    width: 100%;
    height: 100%;
}

.video-info {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid var(--gray-dark);
    position: relative;
}
.video-info h4 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 500;
}

.channel-and-subs {
    height: 52px;
    margin-bottom: 16px;
    align-items: flex-start;
}

.video-description {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid var(--gray-dark)
}
.show-more {
    height: auto;
    overflow: auto;
}
.show-less {
    max-height: 82px;
    overflow: hidden;
}

.subcount.show {
    margin: 8px 0;
    /* font-size: 12px; */
}


/* DROPDOWN *********************/
#video-dropdown {
    width: 150px;
    
    background-color: rgb(33, 33, 33);
    
    position: absolute;
    top: 64px; right: 24px;
    z-index: 10;
}



/* COMMENTS SECTION ********************/
#comments-section {
    padding: 16px;
}


/* SUGGESTED VIDEOS *******************/
.suggested-videos-container {
    width: 100%;
    padding: 0 20px;
}
