.profile-icon-border {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
    position: relative;
}

.profile-icon-border:active {
    border-radius: 100%;
    border: 1px solid var(--blue);
}

.profile-icon {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 100%;
}


/* NAV PROFILE DROPDOWN */
#profile_nav_dropdown {
    position: absolute;
    top: 40px;
    right: -16px;
    width: 300px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: none;

    cursor: auto;
    background-color: var(--gray-dark);
    color: white;
    z-index: 10;
}

.dropdown-header {
    width: 100%;
    height: 80px;
    padding: 16px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dropdown-section {
    width: 100%;
    padding: 6px 0;
}

.dropdown-section > div {
    width: 100%;
    height: 44px;
    padding: 6px;
    
    cursor: pointer;
    font-size: 14px;
    text-align: left;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    transition: background .05s;
}

.dropdown-section > div:hover {
    background: rgb(80, 80, 80);
}
