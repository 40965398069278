#login-page, #signup-page {
    width: calc(100% - 72px);
    height: calc(100vh - 56px);
    margin: 0 auto;
}

#bg-video {
    position: fixed;
    top: 0; right: 0;
    min-width: 100%;
    min-height: 100%;
    
    filter: opacity(.2) blur(7px);
}

#login-form, #signup-form {
    min-width: 300px;
    width: 40%;
    max-width: 560px;
    padding: 64px 56px;
    border-radius: 4px;
    border: 1px solid var(--gray-dark);
    
    background-color: var(--gray-darkest);
    box-shadow: 4px 6px 16px 16px #26262629;
    
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#signup-form {
    transform: translate(-50%, -50%);
    box-shadow: 4px 6px 16px 16px #39393932;
}


/* INPUTS */
#login-form input, #signup-form input {
    width: 100%;
    padding: 12px;
    margin: 16px 0 36px;
    border: 1px solid var(--gray-light);
    border-radius: 4px;
    
    font-size: 16px;
    background-color: transparent;
    outline: none;
    color: white;
}
#login-form input:focus, #signup-form input:focus {
    border: 1px solid var(--blue);
}
