.search {
    height: 40px;
    width: 90%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    
    background-color: var(--black);
}

.header__search input {
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: 1px solid var(--gray-light);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    
    background-color: var(--black);
    color: var(--white-text);
    font-size: 15px;
}
.header__search input:focus {
    outline: none;
    border: 1px solid #78d4ffdc;
}

.search-submit-button {
    width: 64px;
    height: 40px;
    background-color: #313131;
}
