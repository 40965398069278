#home-page {
    width: calc(100% - 72px);
    padding: 0 32px;
}

.video-row-grid {
    width: 100%;
    padding: 24px;
    
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-template-rows: repeat(auto-fill, auto);
    
    gap: 24px;
}

.video-grid-item {
    justify-content: flex-start;
}

.thumbnail-wrapper {
    width: 100%;
    height: 60%;
}
.thumbnail {
    object-fit: cover;
    height: 100%;
    aspect-ratio: 16/9;
}


.video-thumbnail-details {
    width: 100%;
    padding: 16px 0;
}

.video-thumbnail-details h4 {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 14px;
}
.video-thumbnail-details span {
    padding: 2px 0;
    color: var(--gray-light);
    font-size: 12px;
}
