.thumbnail-wrapper {
  width: 100%;
  height: 60%;
}

.thumbnail {
  object-fit: cover;
  height: 100%;
  aspect-ratio: 16/9;
}

.video-thumbnail-details {
  width: 100%;
  padding: 16px 0;
}

.video-thumbnail-details-left {
  width: 11%;
  margin-right: 12px;
}

.video-thumbnail-details-right {
  width: 80%;
}
