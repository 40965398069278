#nav {
    width: 72px;
    height: 100%;
    background-color: var(--gray-dark);
    position: fixed;
    left: 0;
    z-index: 100;
}

#nav a {
    width: 100%;
    height: 74px;
    display: flex;
    
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 10px;
    
    transition: background .05s;
}

#nav .svg-wrapper {
    font-size: 10px;
}

#nav .svg {
    width: 24px;
    height: 24px;
    margin-bottom: 6px;
}

#nav a:hover {
    background: rgb(80, 80, 80);
}
