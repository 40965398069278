.video-card-small {
  height: 100%;
  width: 100%;
  margin: 8px;
}

.video-card-small .thumbnail-wrapper {
  width: 40%;
  min-width: 160px;
}

.video-card-small-details {
  height: 100%;
  width: 100%;
  padding: 2px 8px;
  width: 80%;
}

.video-card-small-details h4 {
    font-weight: 500;
    margin-bottom: 6px;
    font-size: 14px;
}
.video-card-small-details span {
    padding: 2px 0;
    color: var(--gray-light);
    font-size: 12px;
}
