@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --black: #0F0F0F;
    --almost-black: #121212;

    --gray-darkest: #181818;
    --gray-dark: #212121;
    --gray-light: #AAAAAA;

    --white-almost: #E5E5E5;
    --white-text: #E2E2E2;
    --white: white;

    --blue: #3EA6FF;
    --red: #ff3030;
    --red-btn: #CC0000;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin-top: 56px;
    background: var(--gray-darkest);
    color: var(--white);
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.1px;
}

/* TEMPORARY: TO PREVENT NAVBAR OVERLAPPING OTHER ELEMENTS  */
#root > *:not(header):not(nav#nav):not(#video-page) {
    margin-left: 72px;
    /* padding-right: 72px; */
    /* ^ instead of using this padding, use "calc(100% - 72px)" instead */
}

#root {
    display: block;
}

h1,
h2,
h3,
h4 {
    margin: 0;
    padding: 0;
    font-weight: 400;
}

div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

a {
    text-decoration: none;
    color: var(--white);
}

ul {
    list-style: none;
}

button {
    background: none;
    color: var(--white);
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

p {
    line-height: 20px;
}


/* FOR SPECIFIC ELEMENTS */
.svg {
    filter: brightness(0) invert(1);
    width: 26px;
}

.svg-wrapper {
    width: 40px;
    height: 40px;
}

span.subcount {
    padding: 2px 0;
    color: var(--gray-light);
    font-size: 14px;
}

.active {
    color: red;
}

.link {
    font-size: 14px;
    font-weight: 400;
    color: var(--blue);
}

.character-count {
    color: var(--gray-light);
}



/* CLASSES ************************************/
/* FLEX ROW */
.row-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.row-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.row-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.row-bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.row-space-even {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.row-space-around {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

/* FLEX COLUMN */
.col-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.col-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.col-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.col-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.col-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.col-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.col-space-even {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.col-space-around {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

/* BOXES */
.full-size {
    width: 100%;
    height: 100%;
}

.round {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fixed-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* VISIBILITY */
.blur {
    filter: blur(4px);
}

.hidden-soft {
    opacity: 0;
    transition: opacity 1s;
}

.hidden-hard {
    display: none;
}

/* MISC */
.test1 {
    border: 1px solid red;
}
.test2 {
    border: 1px solid rgb(0, 183, 255);
}
.test3 {
    border: 1px solid green;
}
.test4 {
    border: 1px solid purple;
}
.test5 {
    border: 1px solid orange;
}

.line-clamp1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.line-clamp2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

.pointer {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}

.red-outline {
    border: 1px solid var(--red);
}


/* BUTTONS **********************************/
.btn {
    padding: .8em 1.2em;
    
    font-size: 14px;
    font-weight: 500;
    color: var(--gray-light);
    border-radius: 2px;
    letter-spacing: .5px;
    text-transform: uppercase;
  
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn--blue {
    background-color: var(--blue);
    color: var(--black);
}

.btn--blue-outline {
    border: 1px solid var(--blue);
    color: var(--blue);
}

.btn--red {
    background-color: var(--red-btn);
    color: var(--white)
}

.btn--red-outline {
    border: 1px solid var(--red);
    color: var(--red);
}
