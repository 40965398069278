#videos-tab {
    width: 100%;
    max-width: 1280px;
    padding: 32px;
}

#videos-tab > h4 {
    width: 100%;
    padding-left: 24px;
    font-size: 16px;
    font-weight: 500;
}


#videos-tab .video-row-grid {
    grid-template-columns: repeat(auto-fit, 210px);
    gap: 8px;
}

.thumbnail {
    aspect-ratio: 16/9;
    
}
