@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
:root {
    --black: #0F0F0F;
    --almost-black: #121212;

    --gray-darkest: #181818;
    --gray-dark: #212121;
    --gray-light: #AAAAAA;

    --white-almost: #E5E5E5;
    --white-text: #E2E2E2;
    --white: white;

    --blue: #3EA6FF;
    --red: #ff3030;
    --red-btn: #CC0000;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin-top: 56px;
    background: var(--gray-darkest);
    color: var(--white);
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.1px;
}

/* TEMPORARY: TO PREVENT NAVBAR OVERLAPPING OTHER ELEMENTS  */
#root > *:not(header):not(nav#nav):not(#video-page) {
    margin-left: 72px;
    /* padding-right: 72px; */
    /* ^ instead of using this padding, use "calc(100% - 72px)" instead */
}

#root {
    display: block;
}

h1,
h2,
h3,
h4 {
    margin: 0;
    padding: 0;
    font-weight: 400;
}

div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

a {
    text-decoration: none;
    color: var(--white);
}

ul {
    list-style: none;
}

button {
    background: none;
    color: var(--white);
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

p {
    line-height: 20px;
}


/* FOR SPECIFIC ELEMENTS */
.svg {
    filter: brightness(0) invert(1);
    width: 26px;
}

.svg-wrapper {
    width: 40px;
    height: 40px;
}

span.subcount {
    padding: 2px 0;
    color: var(--gray-light);
    font-size: 14px;
}

.active {
    color: red;
}

.link {
    font-size: 14px;
    font-weight: 400;
    color: var(--blue);
}

.character-count {
    color: var(--gray-light);
}



/* CLASSES ************************************/
/* FLEX ROW */
.row-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.row-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.row-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.row-bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.row-space-even {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.row-space-around {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

/* FLEX COLUMN */
.col-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.col-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.col-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.col-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.col-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.col-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.col-space-even {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.col-space-around {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

/* BOXES */
.full-size {
    width: 100%;
    height: 100%;
}

.round {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fixed-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* VISIBILITY */
.blur {
    filter: blur(4px);
}

.hidden-soft {
    opacity: 0;
    transition: opacity 1s;
}

.hidden-hard {
    display: none;
}

/* MISC */
.test1 {
    border: 1px solid red;
}
.test2 {
    border: 1px solid rgb(0, 183, 255);
}
.test3 {
    border: 1px solid green;
}
.test4 {
    border: 1px solid purple;
}
.test5 {
    border: 1px solid orange;
}

.line-clamp1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.line-clamp2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

.pointer {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}

.red-outline {
    border: 1px solid var(--red);
}


/* BUTTONS **********************************/
.btn {
    padding: .8em 1.2em;
    
    font-size: 14px;
    font-weight: 500;
    color: var(--gray-light);
    border-radius: 2px;
    letter-spacing: .5px;
    text-transform: uppercase;
  
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn--blue {
    background-color: var(--blue);
    color: var(--black);
}

.btn--blue-outline {
    border: 1px solid var(--blue);
    color: var(--blue);
}

.btn--red {
    background-color: var(--red-btn);
    color: var(--white)
}

.btn--red-outline {
    border: 1px solid var(--red);
    color: var(--red);
}

#login-page, #signup-page {
    width: calc(100% - 72px);
    height: calc(100vh - 56px);
    margin: 0 auto;
}

#bg-video {
    position: fixed;
    top: 0; right: 0;
    min-width: 100%;
    min-height: 100%;
    
    filter: opacity(.2) blur(7px);
}

#login-form, #signup-form {
    min-width: 300px;
    width: 40%;
    max-width: 560px;
    padding: 64px 56px;
    border-radius: 4px;
    border: 1px solid var(--gray-dark);
    
    background-color: var(--gray-darkest);
    box-shadow: 4px 6px 16px 16px #26262629;
    
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#signup-form {
    transform: translate(-50%, -50%);
    box-shadow: 4px 6px 16px 16px #39393932;
}


/* INPUTS */
#login-form input, #signup-form input {
    width: 100%;
    padding: 12px;
    margin: 16px 0 36px;
    border: 1px solid var(--gray-light);
    border-radius: 4px;
    
    font-size: 16px;
    background-color: transparent;
    outline: none;
    color: white;
}
#login-form input:focus, #signup-form input:focus {
    border: 1px solid var(--blue);
}

#header {
    width: 100%;
    height: 56px;
    padding: 0 16px;
    background-color: #212121f3;
    
    position: fixed;
    top: 0;
    z-index: 1000;
}

/* LEFT ********************************************/
.logo-wrapper {
    padding: 18px 14px 18px 16px;
    width: 120px;
    height: 56px;
}

#header__logo {
    width: 100%;
    object-fit: cover;
}


/* MIDDLE ******************************************/
#header__middle {
    max-width: 650px;
    width: 50%;
}


/* RIGHT ********************************************/
#header__right {
    width: 220px;
}

.pfp-wrapper-nav {
    margin: 0 12px;
    width: 34px;
    height: 34px;
}

.sign-in-btn {
    padding: 0 1em;
    font-weight: 500;
}
.sign-in-btn span {
    width: 60px;
}

.svg-blue {
    filter: invert(61%) sepia(100%) saturate(2282%) hue-rotate(183deg) brightness(97%) contrast(110%);
    width: 26px;
}

.profile-icon-border {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
    position: relative;
}

.profile-icon-border:active {
    border-radius: 100%;
    border: 1px solid var(--blue);
}

.profile-icon {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 100%;
}


/* NAV PROFILE DROPDOWN */
#profile_nav_dropdown {
    position: absolute;
    top: 40px;
    right: -16px;
    width: 300px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: none;

    cursor: auto;
    background-color: var(--gray-dark);
    color: white;
    z-index: 10;
}

.dropdown-header {
    width: 100%;
    height: 80px;
    padding: 16px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dropdown-section {
    width: 100%;
    padding: 6px 0;
}

.dropdown-section > div {
    width: 100%;
    height: 44px;
    padding: 6px;
    
    cursor: pointer;
    font-size: 14px;
    text-align: left;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    transition: background .05s;
}

.dropdown-section > div:hover {
    background: rgb(80, 80, 80);
}

.search {
    height: 40px;
    width: 90%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    
    background-color: var(--black);
}

.header__search input {
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: 1px solid var(--gray-light);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    
    background-color: var(--black);
    color: var(--white-text);
    font-size: 15px;
}
.header__search input:focus {
    outline: none;
    border: 1px solid #78d4ffdc;
}

.search-submit-button {
    width: 64px;
    height: 40px;
    background-color: #313131;
}

#nav {
    width: 72px;
    height: 100%;
    background-color: var(--gray-dark);
    position: fixed;
    left: 0;
    z-index: 100;
}

#nav a {
    width: 100%;
    height: 74px;
    display: flex;
    
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 10px;
    
    transition: background .05s;
}

#nav .svg-wrapper {
    font-size: 10px;
}

#nav .svg {
    width: 24px;
    height: 24px;
    margin-bottom: 6px;
}

#nav a:hover {
    background: rgb(80, 80, 80);
}

#about-tab {
    max-width: 1280px;
    width: 100%;
    padding: 50px 60px;
    display: grid;
    grid-template-columns: 65% 25%;
    grid-gap: 10%;
    gap: 10%;
    grid-template-areas: 'left right';
}

#about-tab-left {
    grid-area: left;
}
#about-tab-left p {
    width: 100%;
    font-size: 14px;
    padding: 32px 0;
    border-bottom: 2px solid var(--gray-dark);
}

#about-tab-right {
    grid-area: right;
}
#about-tab-right span {
    width: 100%;
    padding: 16px 0;
    border-bottom: 2px solid var(--gray-dark);
    color: var(--white-text)
}
#about-tab-right span + span {
    font-size: 14px;
}


#videos-tab {
    width: 100%;
    max-width: 1280px;
    padding: 32px;
}

#videos-tab > h4 {
    width: 100%;
    padding-left: 24px;
    font-size: 16px;
    font-weight: 500;
}


#videos-tab .video-row-grid {
    grid-template-columns: repeat(auto-fit, 210px);
    grid-gap: 8px;
    gap: 8px;
}

.thumbnail {
    aspect-ratio: 16/9;
    
}

.thumbnail-wrapper {
  width: 100%;
  height: 60%;
}

.thumbnail {
  object-fit: cover;
  height: 100%;
  aspect-ratio: 16/9;
}

.video-thumbnail-details {
  width: 100%;
  padding: 16px 0;
}

.video-thumbnail-details-left {
  width: 11%;
  margin-right: 12px;
}

.video-thumbnail-details-right {
  width: 80%;
}

#channel-page {
    width: calc(100% - 72px);
    min-height: calc(100vh - 56px);
    background-color: var(--black);
}


#channel-header {
    width: 100%;
    max-height: 400px;
}

#channel-banner {
    width: 100%;
    overflow: hidden;
}
#channel-header img {
    object-fit: cover;
    width: 100%;
}

/* BOTTOM OF HEADER / BUTTONS */
#channel-header__actions {
    max-width: 1280px;
    width: 100%;
    height: 160px;
    padding: 24px 60px 0;
    background-color: var(--gray-darkest);
    display: flex;
    justify-content: center;
}

.channel-info {
    height: 100px;
    margin-bottom: 10px;
}

#channel__tabs-container {
    height: 48px;
}

.tab {
    height: 100%;
    padding: 2px 32px 0;
    border-bottom: 2px solid transparent;
    color: var(--gray-light);
    
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: border-bottom-color .25s ease-in-out;
}
.tab:hover {
    color: var(--white);
    
}

.active-tab {
    color: var(--white);
    border-bottom: 2px solid var(--white);
    padding-top: 2px;
}

.comment-container {
    width: 100%;
    min-height: 50px;
    margin: 8px 0;
    position: relative;
}
/* PROF IMG **********************/
.comment-profile-image {
    width: 30px;
    height: 30px;
    border-radius: 20px;
}


.comment-body {
    width: 100%;
    padding: 0 10px;
    
    font-size: 13px;
    
    position: relative;
}


.comment-channel-name {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 6px;
}
.comment-time-elapsed {
    font-weight: 400;
    font-size: 12px;
    color: var(--gray-light);
}
.comment-content {
    font-size: 14px;
    padding: 0 0 10px;
}


/* DROPDOWN *********************/
#comment-dropdown {
    width: 120px;
    
    background-color: rgb(33, 33, 33);
    
    position: absolute;
    top: 36px; right: 0;
    z-index: 10;
}

#comment-form-wrapper {
    width: 100%;
    margin: 24px 0;
}


#comment-form {
    width: 100%;
}
#comment-form input {
    width: 100%;
    padding: 10px;
    padding-left: 4px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid var(--gray-light);
    
    outline: none;
    background-color: transparent;
    font-size: 14px;
    color: white;
    transition: border-bottom .10s ease-in-out, margin-bottom .10s ease-in-out;
}
#comment-form input:focus {
    margin-bottom: 8px;
    border-bottom: 3px solid white;
}

.video-card-small {
  height: 100%;
  width: 100%;
  margin: 8px;
}

.video-card-small .thumbnail-wrapper {
  width: 40%;
  min-width: 160px;
}

.video-card-small-details {
  height: 100%;
  width: 100%;
  padding: 2px 8px;
  width: 80%;
}

.video-card-small-details h4 {
    font-weight: 500;
    margin-bottom: 6px;
    font-size: 14px;
}
.video-card-small-details span {
    padding: 2px 0;
    color: var(--gray-light);
    font-size: 12px;
}

#video-page {
    width: 87%;
    margin: 0 auto;
    padding: 24px 0;
    display: grid;
    grid-template-columns: 74% 26%;
    grid-template-areas: "left right";
    align-items: start;
}

#video-page .left {
    grid-area: left;
}

#video-page .right {
    grid-area: right;
    width: 100%;
}



.video-wrapper {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
}
#video-page iframe {
    width: 100%;
    height: 100%;
}

.video-info {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid var(--gray-dark);
    position: relative;
}
.video-info h4 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 500;
}

.channel-and-subs {
    height: 52px;
    margin-bottom: 16px;
    align-items: flex-start;
}

.video-description {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid var(--gray-dark)
}
.show-more {
    height: auto;
    overflow: auto;
}
.show-less {
    max-height: 82px;
    overflow: hidden;
}

.subcount.show {
    margin: 8px 0;
    /* font-size: 12px; */
}


/* DROPDOWN *********************/
#video-dropdown {
    width: 150px;
    
    background-color: rgb(33, 33, 33);
    
    position: absolute;
    top: 64px; right: 24px;
    z-index: 10;
}



/* COMMENTS SECTION ********************/
#comments-section {
    padding: 16px;
}


/* SUGGESTED VIDEOS *******************/
.suggested-videos-container {
    width: 100%;
    padding: 0 20px;
}

#home-page {
    width: calc(100% - 72px);
    padding: 0 32px;
}

.video-row-grid {
    width: 100%;
    padding: 24px;
    
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-template-rows: repeat(auto-fill, auto);
    
    grid-gap: 24px;
    
    gap: 24px;
}

.video-grid-item {
    justify-content: flex-start;
}

.thumbnail-wrapper {
    width: 100%;
    height: 60%;
}
.thumbnail {
    object-fit: cover;
    height: 100%;
    aspect-ratio: 16/9;
}


.video-thumbnail-details {
    width: 100%;
    padding: 16px 0;
}

.video-thumbnail-details h4 {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 14px;
}
.video-thumbnail-details span {
    padding: 2px 0;
    color: var(--gray-light);
    font-size: 12px;
}

#upload-page {
    width: calc(100% - 72px);
    min-height: calc(100% - 56px);
    background-color: var(--black);
    padding: 36px 0;
    position: absolute;
}

#video-upload-form {
    height: 100%;
    width: 1056px;
    align-items: flex-start;
    grid-gap: 24px;
    gap: 24px;
    background-color: var(--black);
    
    position: relative;
    right: 16px;
}
#video-upload-form .left {
    width: 54%;
    /* height: 624px; */
}
#video-upload-form .right {
    width: 42%;
    height: 624px;
    padding-top: 46px;
    align-items: flex-end;
}



/* INPUTS */
.input-wrapper {
    width: 100%;
    padding: 12px;
    margin: 16px 0;
    border: 1px solid var(--gray-light);
    border-radius: 4px;
}
#video-upload-form #title-input,
#video-upload-form #description-input {
    width: 100%;
    height: 36px;
    padding: 8px 0;
    
    font-family: inherit;
    font-size: 16px;
    background-color: transparent;
    resize: none;
    overflow: hidden;
    outline: none;
    border: none;
    color: white;
}
#video-upload-form #description-input {
    min-height: 120px;
}



/* IMAGE PREVIEW */
.image-preview {
    width: 260px;
    height: 100%;
    margin: 12px 0;    
    
    overflow: hidden;
    aspect-ratio: 16/9;
    border: 2px solid var(--gray-dark);
    border-radius: 4px;
}
.image-preview img {
    width: 100%;
    object-fit: cover;
}



/* VIDEO PREVIEW */
#video-upload-form iframe {
    width: 100%;
    border-radius: 4px;
    aspect-ratio: 16/9;
}
.video-preview-wrapper {
    border: 1px solid var(--gray-light);
    width: 440px;
    min-height: 248px;
    margin-bottom: 10px;
    border-radius: 4px;
    aspect-ratio: 16/9;
}


/* ERROR CONTAINER */
.error-container {
    height: 220px;
    width: 100%;
    padding: 16px 0;
}
.error-container > div {
    padding: 4px 0;
    font-size: 16px;
    color: var(--gray-light);
}

#not-found-wrapper {
    width: calc(100% - 72px);
    height: calc(100vh - 56px);
    overflow: hidden;
}

#not-found-image {
    width: 500px;
    filter: contrast(132%);
    position: relative;
    top: -40px; right: 40px;
}

#go-back-home {
    font-size: 16px;
    position: absolute;
    top: calc(50% + 132px);
    left: calc(50% - 10px);
    transform: translate(-50%, -50%);
}

.video-card-large {
  margin: 16px;
  min-width: 800px;
}

.video-card-large .thumbnail-wrapper {
  width: 380px;
  min-width: 380px;
}

.video-card-large-details {
  padding: 4px 16px;
  align-items: flex-start;
}

.video-card-large-details h4 {
  margin-bottom: 4px;
  font-size: 18px;
}

.video-card-large-details span {
  padding: 2px 0;
  color: var(--gray-light);
  font-size: 12px;
}

.search-results-page {
  margin: 0 auto;
}

.search-results-container {
  width: 60%;
  min-width: 860px;
  padding: 20px
}

